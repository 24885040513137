
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getOrderGroupList } from '@/api/orderGroup';

@Component({
  name: 'Delivery',
})

export default class extends Vue {
  private loading = true;

  private query = {
    page: 1,
    limit: 10,
  }

  private list = [];

  mounted() {
    this.getOrderGroup();
  }

  private getOrderGroup() {
    getOrderGroupList(this.query).then((res) => {
      this.list = res.data.content;
      this.loading = false;
    });
  }
}
